<template>
    <div></div>
</template>

<script>
    "use strict";

    export default {
        mounted() {
            this.logOut();
        },
        "methods": {
            logOut() {
                localStorage.removeItem("token");
                localStorage.removeItem("expired");
                this.$router.push({ "name": "offices_login" });
            },
        },
    };
</script>
